.App {
    width: 100%;
    text-align: center;
    margin: 20px;
  }
  
  label {
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;
    color: #4c4c4c;
  }
  
  .form-control1 input,
  .form-control1 textarea,
  .form-control1 select {
    display: block;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    min-height: 45px;
  }
  
  .columns {
    display: flex;
    justify-content: space-between;
  }
  .columns .form-control1 {
    width: calc(50% - 10px);
  }
  .form-control1 {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .formclass {
    width: 100%;
    padding: 0 10px;
  }
  
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
      display: block;
      width: 100%;
  }
  
  /* h1 {
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  h3 {
    font-size: 15px;
  } */
  
  .errors {
    color: red;
    font-size: 80%;
  }
  
  .form-control-stepper {
    display: block;
    width: 80%;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-left: 0px;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-control-border {
    display: block;
    width: 100%;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
    padding-top: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .form-box-border {
    display: block;
    width: 100%;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    border: 0px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .form-description-border {
    display: block;
    width: 100%;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    /*padding-left: 33px;
    padding-right: 33px;
    padding-bottom: 35px;
    padding-top: 20px;*/
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .form-div {
    display: block;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    padding-left: 10%;
  }
  
  .padding-stepper {
    padding-left: 20%;
    font-family: "Open Sans", sans-serif;
  }
  
  .border-check {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  .border-check-profile {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
    padding-top: 10px;
  }
  
  .border-check-skills {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 25px;
    padding-bottom: 27px;
    padding-top: 10px;
  }
  
  .padding-gap {
    display: block;
    width: 100%;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 0px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .padding-next-button {
    padding-right: 15.5%;
    padding-left: 15.5%;
    display: block;
    width: 100%;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-top: 20px;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-control-select {
    display: block;
    font-family: "Open Sans", sans-serif;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /*background-color: #f4f5f7*/
  }
  
  
  .button-add-col {
    display: block;
    width: 60%;
    color: #000000;
    border-color: #fff;
    font-family: "Open Sans", sans-serif;
    background-color:#fff;
    padding: 5px 15px;
    border-radius: 5px;
    letter-spacing: 0.025em;
  }
  
  .button-add-col:hover {
      background-color: rgb(245, 238, 238);
  }
  
  .button-padding {
    padding-left: 45%;
    display: block;
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-top: 10px;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .button-delete-padding {
    align-items: center;
    background-image: none;
    font-family: "Open Sans", sans-serif;
    padding-top: 0px;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  
    display:inline-block;
    margin-top: -15px;
  }
  
  .skill-align {
    display: inline-block;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: "Open Sans", sans-serif;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  
  .box {
    width: 3px;
    margin: 0px;
    // margin-right: 30px;
    /* padding-left: 8px;
    padding-right: 8px; */
    /* padding-bottom: 10px;
    padding-top: 10px; */
  }
  
  .mr10 {
    /* margin-right: 10px; */
    padding-left: 8px;
    padding-right: 8px;
    /* padding-bottom: 10px;
    padding-top: 10px; */
  }
  
  .mr20 {
  margin-left: 30px;
  }
  
  .mr80 {
    padding-left: 10%;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  
  
  /*.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }*/
  