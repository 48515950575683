$imgwidth: 250px;
$imgheight: 353px;
$boxShadowColor: rgba(0,0, 0, 0.15);
$margin: 0px 0px 10px 50px;
$radius:4px;


%width-height {
    width: $imgwidth;
    height: $imgheight;
}

.templatediv {
    transition: all 200ms ease 0s;
    position: relative;
    width: $imgwidth;
    margin: $margin;


    .templatelink {
        @extend %width-height;

        display: flex;
        border-radius: $radius;
        box-shadow: $boxShadowColor 2px 2px 4px 2px;
        margin-bottom: 20px;
        transition: all 250ms ease 0s;
        cursor: pointer;
        text-decoration: none;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;

        .templateimg {
            position: relative;
            overflow: hidden;
            display: inline-block;
            @extend %width-height;

            .temp-img {  
                object-fit: scale-down;
                transition: none 0s ease 0s;
            }
        }

        &:hover .templateshadow {
            opacity: 1;
            transition: all 0.2s ease-in;
        }

       

        .templateshadow {
            position: absolute;
            box-sizing: border-box;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius:$radius;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: all 0.2s ease-in;

            .viewTemplateButton {
                text-decoration: none;
                display: block;
                border-radius:$radius;
                color: #eeeeee;
                background-color: #24BFA3;
                //background-color: #1c5bb1;
                box-shadow: $boxShadowColor 2px 2px 4px 2px;
                text-align: center;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 130px;
                height: 50px;
                line-height: 50px;
                position: absolute;
                font-size: 14px;
                font-weight: bold;
                z-index: 5;
            }
        }
    }

    .template-title-div{
        font-family: "Open Sans", sans-serif;
        color: #32325d; 
        font-size: 18px;
        width: $imgwidth;
        display:flex;
        justify-content: center;
        align-items: center;
        

        .template-title{
            font-weight: bold;
            text-align:center;
        }

        .template-desc{
            font-size: 12px;
            color:grey;
            text-align:left;
        }
    }
}


